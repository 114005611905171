<template>
  <b-container>
    <b-row align-h="center">
      <b-col
        lg="6"
        md="9"
        sm="12"
        class="mb-3"
      >
        <b-card
          title="Aday Kaydı"
          :title-position="'bottom'"
        >
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group label="Ad">
                  <b-form-input
                    v-model="firstName"
                    placeholder="Ad"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Soyad">
                  <b-form-input
                    v-model="lastName"
                    placeholder="Soyad"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="E-Posta adresi">
                  <b-form-input
                    v-model="email"
                    placeholder="E-Posta adresi"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Telefon">
                  <b-form-input
                    v-model="phoneNumber"
                    placeholder="Telefon"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group label="Şifre">
                  <b-form-input
                    v-model="password"
                    type="password"
                    placeholder="Şifre"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Şifre (tekrar)">
                  <b-form-input
                    v-model="confirmPassword"
                    type="password"
                    placeholder="Şifre"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-form-group class="mt-3">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                  >
                    Kayıt Ol
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </b-container>

</template>
<script>
import {
  BContainer, BRow, BCol, BForm, BFormGroup, BCard, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BCard,
    BFormInput,
    BButton,
  },
  directives: {
    Ripple,
  },
}
</script>
